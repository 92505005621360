import React, { useEffect, useState } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import { fetchRides, fetchRidesbyMobileNo, fetchRidesFilter } from "../state";
import { GlobalStyle } from "../style/globalStyles";
import { Layout } from "../components/Layout";
import { RidesSection } from "../style/rides";
import { RidesTable } from "../components/RidesTable";
import { BallBeat } from "react-pure-loaders";
function RidesContainer({ ridesData, ridesFlag, fetchRides }) {
  const bidStatusTypes = [
    // { label: "Requested", value: "Requested" },
    { label: "New Bookings", value: "New Bookings" },
    { label: "Ongoing", value: "Ongoing" },
    { label: "Confirmed", value: "Confirmed" },
    // { label: "Partial Cancel", value: "Partial Cancel" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Completed", value: "Completed" },
    { label: "Place Holder", value: "Place Holder" },
  ];

  // const [state, setState] = useState({
  //   label: "New Bookings",
  //   value: "newbookings",
  // });
  const [state, setState] = useState(ridesFlag);
  const [mobileNo, setMobileNo] = useState();
  const [bidStatusTypeOptions, setBidStatusTypeOptions] = useState(
    bidStatusTypes.filter((type) => {
      return type.value !== ridesFlag;
    })
  );
  const dispatch = useDispatch();
  var bidStatusOptions = bidStatusTypeOptions.map((type) => (
    <option name={type.label} value={type.value} key={type.value}>
      {" "}
      {type.label}{" "}
    </option>
  ));

  const loginData = useSelector((state) => state.auth.user);
  const loginBool = Boolean(loginData.portalUserId);

  // console.log(ridesFlag);

  useEffect(() => {
    if (!loginBool) {
      navigate("/login");
    }
    if (ridesData.rides.length === 0) {
      // New Bookings
      fetchRides();
    }
    setState(ridesFlag);
    // console.log(state, ridesFlag);

    setBidStatusTypeOptions(
      bidStatusTypes.filter((type) => {
        return type.value !== ridesFlag && ridesFlag !== "All Rides";
      })
    );
  }, [fetchRides]);
  // console.log(state, ridesFlag);
  const handleMobileChange = (e) => {
    setMobileNo(e.target.value);
  };
  const handleFetchByMobileNo = () => {
    dispatch(fetchRidesbyMobileNo(mobileNo));
  };
  const handleChange = (e) => {
    setState(e.target.value);
    dispatch(fetchRidesFilter(e.target.value));
    setBidStatusTypeOptions(
      bidStatusTypes.filter((type) => {
        return type.value !== e.target.value;
      })
    );
  };

  return (
    <>
      <GlobalStyle />
      <Layout>
        <RidesSection>
          {ridesData.loading ? (
            <h2>
              Loading Rides{" "}
              <span>
                <BallBeat color={"#123abc"} loading={ridesData.loading} />
              </span>{" "}
            </h2>
          ) : ridesData.error ? (
            <>
              <h2>ERROR ⚠️ FETCHING RIDES</h2>
              <br />
              <p style={{ color: "grey" }}>{ridesData.error}</p>
              <br />
              <br />
            </>
          ) : (
            <>
              <h1>Rides List</h1>
              <br />
              {/* {ridesFlag} */}
              <br />
              <select
                style={{
                  height: "2rem",
                  boxSizing: "border-box",
                  border: "none",
                  backgroundColor: "none",
                }}
                onChange={handleChange}
              >
                {ridesFlag === "All Rides" && (
                  <option key={ridesFlag}>{ridesFlag}</option>
                )}
                <option key={state}>{state}</option>
                {bidStatusOptions}
              </select>
              <input
                style={{
                  border: "none",
                  borderBottom: "2px solid lightsteelblue",
                  height: "2rem",
                  margin: " 0rem 2rem",
                  fontSize: "0.9rem",
                  padding: ".1rem .5rem",
                }}
                type="text"
                placeholder="Enter mobile number"
                onChange={handleMobileChange}
              ></input>
              <button
                style={{
                  height: "fit-content",
                  backgroundColor: "seagreen",
                  color: "white",
                  border: "none",
                  padding: ".5rem",
                  cursor: "pointer",
                }}
                onClick={mobileNo && handleFetchByMobileNo}
              >
                Get Rides
              </button>
              <br />
              <br />
              {ridesData.rides.length !== 0 ? (
                <RidesTable rides={ridesData.rides} />
              ) : (
                "No rides found ⚠️"
              )}
            </>
          )}
        </RidesSection>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ridesData: state.rides,
    ridesFlag: state.rides.ridesflag,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRides: () => dispatch(fetchRides()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RidesContainer);
