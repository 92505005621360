import React from "react";
import { Table } from "./Table";
import { TableStyles } from "../style/globalStyles";

export const RidesTable = ({ rides }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Ride ID",
        accessor: "id",
      },
      {
        Header: "Trip ID",
        accessor: "accountSeqId",
      },

      {
        Header: "Live API ID",
        accessor: "liveApiBookingId",
      },
      {
        Header: "Live API Status",
        accessor: (ride) =>
          ride.liveApiStatus
            ? ride.liveApiStatus
            : ride.liveApiBookingId && "Booked",
      },
      {
        Header: "Service Provider",
        accessor: "accountName",
      },
      {
        Header: "From City",
        accessor: "fromCity",
      },
      {
        Header: "Trip Type",
        accessor: "tripType",
      },
      {
        Header: "Car Type",
        accessor: "carType",
      },
      {
        Header: "Advance Amount",
        accessor: (ride) => ride.advanceAmount || "NA",
      },
      {
        Header: "Start Date | Time",
        accessor: "startDate",
      },
    ],
    []
  );

  var data = React.useMemo(() => rides, []);

  var tableRender = (
    <Table
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          // onClick: (e) => console.log("row clicked", rowInfo),
        };
      }}
      // Pass the page url here to navigate to onClick
      extras={true}
      path={"/ride"}
      columns={columns}
      data={data}
    />
  );

  return <TableStyles>{rides ? tableRender : " "}</TableStyles>;
};
